import Navbar from "../components/Navbar.js";
import Web3 from "web3";
import config from "../config/config";
// import ConnectWallet from "../pages/seperate/connect-wallert";
// import { getCurAddr1 } from "../action/user";
import moment from "moment";
import "../App.css";
import { toast } from "react-toastify";
import lotteryABI from "../ABI/Lottery.json";
import Paper from "@material-ui/core/Paper";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import TableHead from "@material-ui/core/TableHead";
import TableCell from "@material-ui/core/TableCell";
import { withStyles, lighten, makeStyles } from "@material-ui/core/styles";
import customInputStyle from "../assets/jss/material-dashboard-react/components/customInputStyle";

import React, { useState, useEffect, Fragment } from "react";
import PropTypes from "prop-types";
// toast.configure();
import ReactDatatable from "@ashvin27/react-datatable";
import SimpleDateTime from "react-simple-timestamp-to-date";
import {
  getlotterylist,
  completeTicket,
  closeComTicket,
} from "../action/admin";
import { getCurAddr } from "../action/Apicontroller";

let toasterOption = config.toasterOption;

function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}
////
const headCells = [
  { id: "lotteryId", numeric: false, disablePadding: true, label: "LotteryId" },
  { id: "endTime", numeric: true, disablePadding: false, label: "End Time" },
  {
    id: "ticketPice",
    numeric: true,
    disablePadding: false,
    label: "ticketPice",
  },
  // { id: "discount", numeric: true, disablePadding: false, label: "Discount" },
  { id: "fees", numeric: true, disablePadding: false, label: "Fees" },
  { id: "status", numeric: true, disablePadding: false, label: "Status" },
  // {
  //   id: "created_date",
  //   numeric: true,
  //   disablePadding: false,
  // },
  { id: "Action", numeric: true, disablePadding: false, label: "Action" },
];

function EnhancedTableHead(props) {
  const {
    classes,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={"left"}
            // padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: "1 1 100%",
  },
}));
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
}));
const customStyle = makeStyles(customInputStyle);
export default function Settings(props) {
  const [open, setOpen] = useState(true);
  const [anchorEl, setAnchorEl] = useState(null);
  const [provider, setprovider] = React.useState();
  const [address, setaddress] = React.useState();

  // const [UserAccountAddr, Set_UserAccountAddr] = React.useState("");
  // const [UserAccountBal, Set_UserAccountBal] = React.useState(0);
  // const [WalletConnected, Set_WalletConnected] = React.useState("false");
  // const [AddressUserDetails, Set_AddressUserDetails] = useState({});
  // const [Accounts, Set_Accounts] = React.useState("");
  const [responsive, setresponsive] = useState(true);
  const [loading, setloading] = useState(false);
  const [userdet, setUser] = useState();

  ////
  const customStyles = customStyle();
  const classes = useStyles();
  ////

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDrawerOpen = () => {
    setOpen(!open);
  };

  // const AfterWalletConnected = async () => {
  //   var currAddr = await getCurAddr1();
  //   if (currAddr) {
  //     //getTokenbal();
  //   }
  // };
  useEffect(() => {
    getmyprovider();
    getLotteryList();

    // if (provider && address) {
    // }
  }, [provider, address]);

  async function getmyprovider() {
    let datas = await getCurAddr();
    let web3call = new Web3(
      datas &&
      datas.provider &&
      datas.provider != null &&
      datas.provider != undefined &&
      datas.provider != ""
        ? datas.provider
        : window.ethereum
    );
    console.log(web3call._provider, "datas**");
    var result = await web3call.eth.getAccounts();
    console.log(result[0], "result");
    setprovider(web3call._provider);
    setaddress(result[0]);
  }
  ///
  async function closeTicket(data) {
    var id = data._id;
    setloading(true);
    if (id != "") {
      let datas = await getCurAddr();
      if (
        !(
          datas &&
          datas.address &&
          datas.address != undefined &&
          datas.address != null &&
          datas.address != "" &&
          localStorage.getItem("account")
        )
      ) {
        toast.warning("OOPS!..connect Your Wallet", toasterOption);
        setloading(false);
        return false;
      }
      let web3 = new Web3(
        datas &&
        datas.provider &&
        datas.provider != null &&
        datas.provider != undefined &&
        datas.provider != ""
          ? datas.provider
          : window.ethereum
      );
      var currAddress = await web3.eth.getAccounts();

      // var currAddr =  await web3.eth.getAccounts();
      if (!currAddress) {
        toast.warning("OOPS!..connect Your Wallet", toasterOption);
        setloading(false);
        return false;
      }
      if (currAddress[0].toLocaleLowerCase() != config.lotterAdmin.toLocaleLowerCase()) {
        toast.error("Please  Connect with Owner Wallet", toasterOption);
        setloading(false);
        return false;
      }
      // if(currAddr!=config.ownerAddr){
      //   toast.warn("Please use Operator Address",toasterOption);
      //   return false;
      // }
      const Contract = new web3.eth.Contract(
        lotteryABI,
        config.ContractAddress
      );
      try {
        var result = await Contract.methods
          .closeLottery(data.lotteryId)
          .send({ from: currAddress[0] });
        console.log(id, "idididid");
      } catch (err) {
        console.log(err, "err");
        toast.warning("OOPS!..Rejected", toasterOption);
        setloading(false);
        return false;
      }
      console.log("next line comming");
      var result1 = await closeComTicket({ id });
      toast.success("Ticket closed successfully", toasterOption);
      setloading(false);
      getLotteryList();
    }
  }


  const getLotteryList = async () => {
    var test = await getlotterylist();
    console.log(test.userValue, "uservalue......");
    setUser(test.userValue);
  };



  async function complete(data) {
    console.log(data, "datadatatatataatatat");
    setloading(true);
    var id = data._id;
    if (id != "") {
      let datas = await getCurAddr();
      if (
        !(
          datas &&
          datas.address &&
          datas.address != undefined &&
          datas.address != null &&
          datas.address != "" &&
          localStorage.getItem("account")
        )
      ) {
        toast.warning("OOPS!..connect Your Wallet", toasterOption);
        setloading(false);
        return false;
      }
      let web3 = new Web3(
        datas &&
        datas.provider &&
        datas.provider != null &&
        datas.provider != undefined &&
        datas.provider != ""
          ? datas.provider
          : window.ethereum
      );
      // var currAddr = window.web3.eth.defaultAccount;
      var currAddress = await web3.eth.getAccounts();

      if (!currAddress) {
        toast.warning("OOPS!..connect Your Wallet", toasterOption);
        setloading(false);
        return false;
      }

      if (currAddress[0] != config.lotterAdmin) {
        toast.error("Please  Connect with Owner Wallet", toasterOption);
        setloading(false);
        return false;
      }

      const Contract = new web3.eth.Contract(
        lotteryABI,
        config.ContractAddress
      );
      try {
        var result = await Contract.methods
          .drawFinalNumberAndMakeLotteryClaimable(data.lotteryId, true)
          .send({ from: currAddress[0] });
        // .catch(err=>{
        //   toast.warning("OOPS!..Rejected", toasterOption);
        //   setloading(false);
        //   return false;
        // })
      } catch (err) {
        console.log(err, "err");
        toast.warning("OOPS!..Rejected", toasterOption);
        setloading(false);
        return false;
      }
      console.log("next line comming");
      var result1 = await completeTicket({ id });
      toast.success("Ticket Completed Successfully", toasterOption);
      setTimeout(() => {
        window.location.reload();
      }, 3000);
      setloading(false);
      getLotteryList();
    }
  }

  function pageChange(pageData) {
    console.log("OnPageChange", pageData);
  }

  const configdata = {
    page_size: 10,
    length_menu: [10, 20, 50],
    filename: "Users",
    no_data_text: "No user found!",

    language: {
      length_menu: "Show _MENU_ Result per page",
      filter: "Filter in records...",
      info: "Showing _START_ to _END_ of _TOTAL_ records",
      pagination: {
        first: "First",
        previous: "Previous",
        next: "Next",
        last: "Last",
      },
    },
    show_length_menu: true,
    show_filter: false,
    show_pagination: true,
    show_info: true,
    defaultSortAsc: true,
  };

  const columns = [
    {
      key: "lotteryId",
      text: "Lottery Id",
      className: "answer",
      align: "left",
      sortable: false,
    },
    {
      key: "created_date",
      text: "Start Date",
      className: "answer",
      align: "left",
      sortable: false,
      cell: (record) => {
        var convert = moment(record.created_date).format("LLLL");
        return convert;
      },
    },
    {
      key: "endTime",
      text: "End Time",
      className: "answer",
      align: "left",
      sortable: false,
      cell: (record) => {
        return (
          <SimpleDateTime
            dateSeparator="-"
            format="MYD"
            timeSeparator=":"
            meridians="1"
          >
            {record.endTime}
          </SimpleDateTime>
        );
      },
    },
    {
      key: "ticketPice",
      text: "Ticket Price",
      className: "answer",
      align: "left",
      sortable: false,
    },
    {
      key: "action",
      text: "Action",
      className: "action",
      width: 200,
      align: "left",
      sortable: false,
      cell: (record) => {
        if (record.status == 0 && new Date(record.endDate) > new Date()) {
          return (
            <Fragment>
              <button
                data-toggle="modal"
                data-target="#update-template-modal"
                className="btn btn-primary btn-sm"
                style={{ marginRight: "5px" }}
              >
                Open
              </button>
            </Fragment>
          );
        } else if (record.status == 0) {
          return (
            <Fragment>
              <button
                data-toggle="modal"
                data-target="#update-template-modal"
                className="btn btn-primary btn-sm"
                style={{ marginRight: "5px" }}
                onClick={() => closeTicket(record)}
              >
                {loading && <i class="fas fa-spinner fa-spin mr-2"></i>}
                Close Lottery
              </button>
            </Fragment>
          );
        } else if (record.status == 1) {
          return (
            <Fragment>
              <button
                data-toggle="modal"
                data-target="#update-template-modal"
                className="btn btn-primary btn-sm"
                onClick={() => complete(record)}
                style={{ marginRight: "5px" }}
              >
                {loading && <i class="fas fa-spinner fa-spin mr-2"></i>}
                Distribute Rewards
              </button>
            </Fragment>
          );
        } else {
          return (
            <Fragment>
              <button
                data-toggle="modal"
                data-target="#update-template-modal"
                className="btn btn-primary btn-sm"
                style={{ marginRight: "5px" }}
              >
                Completed
              </button>
            </Fragment>
          );
        }
      },
    },
  ];

  console.log(userdet,"userdet")
  return (
    <div>
      <ScrollToTopOnMount />
      <section className="lottery_list">
        <div className="container">
          <h2 className="inner_title mb-4">Lottery List</h2>
          <Paper className={classes.paper}>
            <div className="table-responsive">
              <ReactDatatable
                responsive={responsive}
                config={configdata}
                records={userdet}
                columns={columns}
                onPageChange={pageChange()}
              />
            </div>
          </Paper>
        </div>
      </section>
    </div>
  );
}
