let NODE_ENV = "demo";

let Front_URL = "";
let Base_URL = "";
let rpcURL = "";
let decimalvalues = 1000000000000000000;
let toFixed = 4;
let networkVersion = "";
let bscurl = "";  
let adminAddress = "";
let bonjourcontract = "";
let busdcontract = "";
let icocontract = "";
let autopoolcontract = "";
let bonjourtokencontract = "";
let bnbtousd = "";
let chainId = "";
let Server_URL = "";
const ownerAddr = "0x49E1a781BffF290350d0EBA905D42823F2308F39";

let toasterOption = {
  position: "top-right",
  autoClose: 2000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: false,
  draggable: true,
  progress: undefined,
};



var lottery = "";
var lotterAdmin = "";
var lotteryToken = "";

var singleContract = "";
var WbnbtokenAddr = "";
var PankukuAddr = "";
var ContractAddress = "";




if (NODE_ENV === "production") {
 
} else if (NODE_ENV === "demo") {
  //Front_URL = "http://localhost:3000/";
  Front_URL = "https://demo-matrix-frontend.pages.dev/";
  Server_URL = "https://demomatrixapi.wearedev.team/";
  networkVersion = 97;
  rpcURL = "https://data-seed-prebsc-1-s3.binance.org:8545/";
  bscurl = "https://testnet.bscscan.com/";
  adminAddress = "0x373f886904048975034488320e37d171e092c135";
  bonjourcontract = "0x3371E695426d1FE94781fF0d0303684ce6803225"; //0x908fDed1E68C2ADf0CB4a5f8684ADC747F25b64A
  busdcontract = "0x8CE7720fD183AeC96b676FD8250724b05b0d7a6F";
  icocontract        = "0x3EcA041FA2CAd62B71C851Ba4883f306AC38713b"
  bnbtousd = "0x0567F2323251f0Aab15c8dFb1967E4e8A7D42aeE";
  chainId = 97;
  bonjourtokencontract = "0x830BEB23d72D79dB719CfDBDf86731f96F52aB89";
  autopoolcontract = "0xC5Fa18e30e8d8438351276478781297181B72563";
  lottery = "0x60dF5E3D560E24f035981FFe4C0f697c860B130B";
  lotterAdmin = "0x49E1a781BffF290350d0EBA905D42823F2308F39";
  lotteryToken = "0x498532dc25045289E34A5Be1A050b3B30C024fcc";
  singleContract = "0xF739B96ad8B4efcebB7EcAffed2f560ACDB64517";
  WbnbtokenAddr = "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c";
  PankukuAddr = "0x1dB3c1545df0c705cceEf4F7d33EFE8854bB8254";
  ContractAddress = "0x60dF5E3D560E24f035981FFe4C0f697c860B130B";
} else {
  Front_URL = "http://localhost:3000/";
  Server_URL = "http://localhost:2053/";
  networkVersion = 97;
  rpcURL = "https://data-seed-prebsc-1-s3.binance.org:8545/";
  bscurl = "https://testnet.bscscan.com/";
  adminAddress = "0x373f886904048975034488320e37d171e092c135";
  bonjourcontract = "0x8132B7d197a444958e89cd8ff41811659108B146"; //0x908fDed1E68C2ADf0CB4a5f8684ADC747F25b64A
  bonjourtokencontract = "0x830BEB23d72D79dB719CfDBDf86731f96F52aB89";
  icocontract        = "0x3EcA041FA2CAd62B71C851Ba4883f306AC38713b"
  busdcontract = "0x8CE7720fD183AeC96b676FD8250724b05b0d7a6F";
  autopoolcontract = "0xC5Fa18e30e8d8438351276478781297181B72563";
  bnbtousd = "0x0567F2323251f0Aab15c8dFb1967E4e8A7D42aeE";
  chainId = 97;
  //
  lottery = "0x19A49BD81E360D89Cd5457A9955615002Eb57F72";
  lotterAdmin = "0x49E1a781BffF290350d0EBA905D42823F2308F39";
  lotteryToken = "0x498532dc25045289E34A5Be1A050b3B30C024fcc";

  singleContract = "0xF739B96ad8B4efcebB7EcAffed2f560ACDB64517";
  WbnbtokenAddr = "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c";
  PankukuAddr = "0x1dB3c1545df0c705cceEf4F7d33EFE8854bB8254";
  ContractAddress = "0x19A49BD81E360D89Cd5457A9955615002Eb57F72";
}

let key = {
  Front_URL: Front_URL,
  Server_URL: Server_URL,
  decimalvalues: decimalvalues,
  toFixed: toFixed,
  rpcURL: rpcURL,
  networkVersion: networkVersion,
  toasterOption: toasterOption,
  adminAddress: adminAddress,
  bonjourcontract: bonjourcontract,
  busdcontract: busdcontract,
  autopoolcontract: autopoolcontract,
  bonjourtokencontract: bonjourtokencontract,
  icocontract : icocontract,
  bnbtousd: bnbtousd,
  bscurl: bscurl,
  chainId: chainId,
  ownerAddr: ownerAddr,
  lotteryToken: lotteryToken,
  lottery: lottery,
  lotterAdmin: lotterAdmin,
  ContractAddress: ContractAddress,
  PankukuAddr: PankukuAddr,
  singleContract: singleContract,
  WbnbtokenAddr: WbnbtokenAddr,
};

export default key;
