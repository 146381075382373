import React , { useEffect, useState }  from "react";
import { useParams } from "react-router-dom";
import ScrollAnimation from "react-animate-on-scroll";
import ReactDatatable from "@ashvin27/react-datatable";
import syncEach from 'sync-each';
import Web3 from "web3";
import Navbar_inner from "../components/Navbar_inner";
import autopoolapi from "../ABI/autopool";
import mlmapi from "../ABI/bonjourABI";
import busdabi from "../ABI/busdABI";
import bonjourapi from "../ABI/bonjourtokenABI";
import config from "../config/config";
import { toastAlert } from "../helper/toastAlert";
import { getCurAddr } from "../action/Apicontroller";


export default function Autopool() {

    // const columns = [
    //     {
    //         key: "name",
    //         text: "Name",
    //         className: "name",
    //         align: "left",
    //         sortable: true,
    //     },
    //     {
    //         key: "address",
    //         text: "Address",
    //         className: "address",
    //         align: "left",
    //         sortable: true
    //     },
    //     {
    //         key: "postcode",
    //         text: "Postcode",
    //         className: "postcode",
    //         sortable: true
    //     },
    //     {
    //         key: "rating",
    //         text: "Rating",
    //         className: "rating",
    //         align: "left",
    //         sortable: true
    //     },
    //     {
    //         key: "type_of_food",
    //         text: "Type of Food",
    //         className: "type_of_food",
    //         sortable: true,
    //         align: "left"
    //     },
    // ]

    const records = [
         {
            SNo:"1",
            Address: "bc1qam3fkm5lnrnvg8s9y77xhjyesgs7urd0m7zht4",
            InvestmentAmount: "2247",
            EarnedAmount:"2247",
          },
          {
            SNo:"2",
            Address: "1MUVs3V8Y2LS6pbQruhc2C8JJw8YGhGrU3",
            InvestmentAmount: "535",
            EarnedAmount:"535",
          },
          {
            SNo:"3",
            Address: "3JSAAC2LyWPpXPmMssQzouhCDx4m7ymnts",
            InvestmentAmount: "15465",
            EarnedAmount:"15465",
          },
          {
            SNo:"4",
            Address: "bc1qam3fkm5lnrnvg8s9y77xhjyesgs7urd0m7zht4",
            InvestmentAmount: "782",
            EarnedAmount:"782",
          },
          {
            SNo:"5",
            Address: "1MUVs3V8Y2LS6pbQruhc2C8JJw8YGhGrU3",
            InvestmentAmount: "65",
            EarnedAmount:"65",
          },
          {
            SNo:"6",
            Address: "3JSAAC2LyWPpXPmMssQzouhCDx4m7ymnts",
            InvestmentAmount: "2547",
            EarnedAmount:"2547",
          },
          {
            SNo:"7",
            Address: "bc1qam3fkm5lnrnvg8s9y77xhjyesgs7urd0m7zht4",
            InvestmentAmount: "6269",
            EarnedAmount:"6269",
          },
          {
            SNo:"8",
            Address: "1MUVs3V8Y2LS6pbQruhc2C8JJw8YGhGrU3",
            InvestmentAmount: "3641",
            EarnedAmount:"3641",
          },
          {
            SNo:"9",
            Address: "3JSAAC2LyWPpXPmMssQzouhCDx4m7ymnts",
            InvestmentAmount: "4369",
            EarnedAmount:"4369",
          },
          {
            SNo:"10",
            Address: "bc1qam3fkm5lnrnvg8s9y77xhjyesgs7urd0m7zht4",
            InvestmentAmount: "841",
            EarnedAmount:"841",
          },
          {
            SNo:"11",
            Address: "1MUVs3V8Y2LS6pbQruhc2C8JJw8YGhGrU3",
            InvestmentAmount: "535",
            EarnedAmount:"535",
          },
          {
            SNo:"12",
            Address: "3JSAAC2LyWPpXPmMssQzouhCDx4m7ymnts",
            InvestmentAmount: "15465",
            EarnedAmount:"15465",
          },
    ]

  
    const configdata = {
        page_size: 10,
        length_menu: [2, 2, 50],
        filename: "Users",
        no_data_text: "No user found!",
    
        language: {
          length_menu: "Show _MENU_ result per page",
          filter: "Filter in records...",
          info: "Showing _START_ to _END_ of _TOTAL_ records",
          pagination: {
            first: "First",
            previous: "Previous",
            next: "Next",
            last: "Last",
          },
        },
        show_length_menu: false,//true
        show_filter: false,
        show_pagination: true,
        show_info: true,
        defaultSortAsc: true,
      };
    
      const columns = [
        {
          key: "sno",
          text: "S.No",
          className: "head",
          align: "left",
          sortable: false,
        },
        {
            key: "address",
            text: "Address",
            className: "head",
            align: "left",
            sortable: false,
          },
          {
            key: "investmentAmount",
            text: "Investment Amount",
            className: "head",
            align: "left",
            sortable: false,
          },
          {
            key: "earnedAmount",
            text: "Earned Amount",
            className: "head",
            align: "left",
            sortable: false,
          },
      ];
  var { id } = useParams();
  const [data,setdata] = useState({});
  const [userdata,setuserdata] = useState({});
  const [investamt,setinvestamt] = useState(0);
  const [minval,setminval] = useState(10);
  const [maxval,setmaxval] = useState(500);
  const [progress,setprogress] = useState("false");
  const [newprogress,setnewprogress] = useState("false");
  const [totaluser,settotaluser] = useState(0);
  const [totalgainamt,settotalgainamt] = useState(0);
  const [reinvestcount,setreinvestcount] = useState(0);
  const [usertokenbalance,setusertokenbalance] = useState(0);
  const [poolarray,setpoolarray] = useState([]);
  const [referal,setreferal] = useState(id);
  const [first_user,setfirst_user] = useState("");
  const [refgain,setrefgain] = useState(0);
  const [newgainamt,setnewgainamt]  = useState(0);
  const [roiupto,setroiupto]  = useState(0);

  useEffect(() => {
    getdetails();
  }, []);

  async function getdetails(){
   let datas = await getCurAddr();
   if(datas && datas.address && 
      datas.address!=undefined && 
      datas.address!=null && 
      datas.address!="" && localStorage.getItem("account")){
       setdata(datas);
       let web3 =  new Web3(datas && datas.provider && datas.provider!=null && datas.provider!=undefined && datas.provider!=""?datas.provider:window.ethereum);
       var AutopoolContract = new web3.eth.Contract(autopoolapi, config.autopoolcontract);
       var bonjourContract = new web3.eth.Contract(bonjourapi, config.bonjourtokencontract);
       let user_details = await AutopoolContract.methods.users(localStorage.getItem("account")).call();
       let firstuser = await AutopoolContract.methods.ownerAddress().call();
       setfirst_user(firstuser);
       let tot_user = await AutopoolContract.methods.totalParticipants().call();
       settotaluser(tot_user);
       let tot_gain = await AutopoolContract.methods.totalGainAmount(localStorage.getItem("account")).call();
       settotalgainamt(tot_gain);
       let ref_gain = await AutopoolContract.methods.referalGainAmount(localStorage.getItem("account")).call();
       setrefgain(ref_gain);
       let rein_count = await AutopoolContract.methods.reinvestCount(localStorage.getItem("account")).call();
       setreinvestcount(rein_count);
       let roi_upto = await AutopoolContract.methods.checkRoiUpto(localStorage.getItem("account")).call();
       setroiupto(roi_upto);
       setuserdata(user_details);
       let bonjourbalance = await bonjourContract.methods.balanceOf(localStorage.getItem("account")).call();
       setusertokenbalance(bonjourbalance);
       let new_gain = await AutopoolContract.methods.referalNewGainAmount(localStorage.getItem("account")).call();
       setnewgainamt(new_gain);
       // let min_val = await AutopoolContract.methods.minInvestment().call();
       // min_val = min_val/1e18;
       // setminval(min_val);
       setinvestamt(minval);
       let arr = await AutopoolContract.methods.getAllPoolAddress().call();
       let pool_array=[];
       let i = 0 ; 
       syncEach(arr, async function (items, next) {
        i++;
        let detail = await AutopoolContract.methods.users(items).call();
        let obj = {
          "sno":i,
          "address":items,
          "investmentAmount": (parseFloat(detail.investmentAmount)/1e18).toFixed(2),
          "earnedAmount": (parseFloat(detail.earnedAmount)/1e18).toFixed(2)
        }
        pool_array.push(obj);
        next();
       },function (err, transformedItems) {
        setpoolarray(pool_array);
       })
    }else{
      setdata("");
    }
  }

  async function copyToClipboard(e) {
    var textField = document.createElement("textarea");
    textField.innerText = localStorage.getItem("account");
    document.body.appendChild(textField);
    textField.select();
    document.execCommand("copy");
    textField.remove();
    toastAlert("success", "Copied Successfully", "success");
  }

  async function copyToClipboardlink(e) {
    var textField = document.createElement("textarea");
    textField.innerText = config.Front_URL+"bonjourpool/"+ localStorage.getItem("account");
    document.body.appendChild(textField);
    textField.select();
    document.execCommand("copy");
    textField.remove();
    toastAlert("success", "Copied Successfully", "success");
  }


  async function alertsms(){
    toastAlert('error', "Connect Wallet to Proceed Further", 'network');
  }

  async function withdraw(){
    try{
      setprogress("true")
      let datas = await getCurAddr();
      if(datas && datas.address && 
            datas.address!=undefined && 
            datas.address!=null && 
            datas.address!="" && localStorage.getItem("account")){
        let web3 =  new Web3(datas && datas.provider && datas.provider!=null && datas.provider!=undefined && datas.provider!=""?datas.provider:window.ethereum);
        var busdContract = new web3.eth.Contract(busdabi, config.busdcontract);
        var AutopoolContract = new web3.eth.Contract(autopoolapi, config.autopoolcontract);
        let contractbalace = await busdContract.methods.balanceOf(config.autopoolcontract).call();
        if(userdata && userdata.investmentAmount > 0 && parseFloat(userdata.investmentAmount)*2==parseFloat(userdata.earnedAmount)){
            //if(userdata.earnedAmount<=contractbalace){
              let claimed = await AutopoolContract.methods.claim().send({
                    from: datas.address
              })
              setprogress("false")
              toastAlert('success', "Withdraw Successfully Done", 'network');
              window.location.reload();
            // }else{
            //   setprogress("false")
            //   toastAlert('error',"Admin does not have enough balance for withdraw", 'network');
            // }
        }else{
          setprogress("false")
          toastAlert('error', "Withdraw not available for you", 'network');
        }
      }else{
        setprogress("false")
        toastAlert('error', "Please select BSC chain network", 'network');
      }
    }catch(err){
        setprogress("false")
        toastAlert('error', "Process Cancelled", 'network');
    }
  }

  async function joinnow(){
    try{
      setprogress("true")
      let datas = await getCurAddr();
      if(datas && datas.address && 
            datas.address!=undefined && 
            datas.address!=null && 
            datas.address!="" && localStorage.getItem("account")){
        let web3 =  new Web3(datas && datas.provider && datas.provider!=null && datas.provider!=undefined && datas.provider!=""?datas.provider:window.ethereum);
        var mlmContract = new web3.eth.Contract(mlmapi, config.bonjourcontract);
        var busdContract = new web3.eth.Contract(busdabi, config.busdcontract);
        var AutopoolContract = new web3.eth.Contract(autopoolapi, config.autopoolcontract);
        var bonjourContract = new web3.eth.Contract(bonjourapi, config.bonjourtokencontract);
        let mlmuser = await mlmContract.methods.users(localStorage.getItem("account")).call();
        let bonjourbalance = await bonjourContract.methods.balanceOf(localStorage.getItem("account")).call();
        bonjourbalance = bonjourbalance/1e18;
        let busdbalace = await busdContract.methods.balanceOf(localStorage.getItem("account")).call();
        busdbalace = busdbalace/1e18;
        if(investamt && parseFloat(investamt)>0){
          if(parseFloat(busdbalace)>=parseFloat(investamt)){
            //if(mlmuser && mlmuser.isExist){
              console.log(investamt,"====",minval)
              if(investamt>=minval){
                if((investamt<=maxval)||(userdata && userdata.isExist && (parseFloat(userdata.claimBalance)/1e18)>=maxval)){
                if(bonjourbalance >= (parseFloat(investamt)/2)){
                  console.log(investamt,"=====investamt amt")
                  let invest_amt =  (parseFloat(investamt) * 1e18)
                  let sendamt = await convert(invest_amt);
                  let app_value = await convert(1000000000000000000000000000);
                  console.log(sendamt,"=====send amt")
                  let referraladdess = referal && referal!=undefined && referal!=null && referal!="" ? referal : first_user;
                  if(web3.utils.isAddress(referraladdess) || (userdata && userdata.isExist)){
                      if((referraladdess).toLowerCase()!=(datas.address).toLowerCase() || (userdata && userdata.isExist)){
                        let user_details = await AutopoolContract.methods.users(referraladdess).call();
                          if(((user_details && user_details.isExist && referal && referal!=undefined && referal!=null && referal!="" )|| first_user == referraladdess ) || (userdata && userdata.isExist)){
                          var allowance = await busdContract.methods.approve(config.autopoolcontract,app_value.toString()).send({ 
                            from: datas.address
                          });
                          console.log("=====afte")
                          if(allowance){
                            if(userdata && userdata.isExist){
                              if((userdata && userdata.claimBalance>0 && parseFloat(userdata.claimBalance)<=parseFloat(sendamt))||(userdata.claimBalance==0)){
                                let register = await AutopoolContract.methods.reInvest(sendamt.toString()).send({
                                      from: datas.address
                                })
                                setprogress("false")
                                toastAlert('success', "Re-Invest Successfully Done", 'network');
                                window.location.reload();
                              }else{
                                setprogress("false")
                                toastAlert('error', "Reinvet Amount must be greator than or equal to Lock Amount", 'network');
                              }
                            }else{
                              let register = await AutopoolContract.methods.regUser(sendamt.toString(),referraladdess).send({
                                    from: datas.address
                              })
                              setprogress("false")
                              toastAlert('success', "Joined Successfully", 'network');
                              window.location.reload();
                            }
                          }else{
                            setprogress("false")
                            toastAlert('error', "Process Cancelled , Try Again ", 'network');
                          }
                    }else{
                      setprogress("false")
                      toastAlert('error', "Insufficient Bonjour Token Balance", 'network');
                    }

                    }else{
                      setprogress("false")
                      toastAlert('error', "Referral User must entrolled in Autopool", 'network');
                    }
                  }else{
                    setprogress("false")
                    toastAlert('error', "Enter Valid Referral Address", 'network');
                  }
                }else{
                  setprogress("false")
                  toastAlert('error', "Investment Amount must be Half of your Bonjour balance", 'network');
                }
               }else{
                setprogress("false")
                let sms = "Maximum invest Amount : "+ maxval;
                toastAlert('error', sms, 'network');
               }
              }else{
                setprogress("false")
                let sms = "Minimum invest Amount : "+ minval;
                toastAlert('error', sms, 'network');
              }
            // }else{
            //   setprogress("false")
            //   toastAlert('error', "You must Enrolled in MLM to join Auto Pool", 'network');
            // }
          }else{
            setprogress("false")
            toastAlert('error', "Insufficient Balance", 'network');
          }
        }else{
          setprogress("false")
          toastAlert('error', "Enter Valid Amount", 'network');
        }
      }else{
        setprogress("false")
        toastAlert('error', "Please select BSC chain network", 'network');
      }
    }catch(err){
      console.log(err,"====errrrrrr")
      setprogress("false")
      toastAlert('error', "Process Cancelled", 'network');
    }
  }

  const investamtchange = (e) =>{
    setinvestamt(e.target.value);
  }

  const referralchange = (e) => {
    setreferal(e.target.value)
  }

  async function empty(){

  }

  async function newclaimreward(){
    try{
      setnewprogress("true")
      let datas = await getCurAddr();
      if(datas && datas.address && 
            datas.address!=undefined && 
            datas.address!=null && 
            datas.address!="" && localStorage.getItem("account")){
        let web3 =  new Web3(datas && datas.provider && datas.provider!=null && datas.provider!=undefined && datas.provider!=""?datas.provider:window.ethereum);
        var AutopoolContract = new web3.eth.Contract(autopoolapi, config.autopoolcontract);
        if(newgainamt && parseFloat(newgainamt)>0 ){
              let claimed = await AutopoolContract.methods.claimAirdrop().send({
                    from: datas.address
              })
              setnewprogress("false")
              toastAlert('success', "claimed Successfully Done", 'network');
              window.location.reload();
        }else{
          setnewprogress("false")
          toastAlert('error', "Claim not available for you", 'network');
        }
      }else{
        setnewprogress("false")
        toastAlert('error', "Please select BSC chain network", 'network');
      }
    }catch(err){
        setnewprogress("false")
        toastAlert('error', "Process Cancelled", 'network');
    }
  }

  function convert(n){
    var sign = +n < 0 ? "-" : "",
    toStr = n.toString();
    if (!/e/i.test(toStr)) {
      return n;
    }
    var [lead,decimal,pow] = n.toString()
     .replace(/^-/,"")
     .replace(/^([0-9]+)(e.*)/,"$1.$2")
     .split(/e|\./);
     return +pow < 0
    ? sign + "0." + "0".repeat(Math.max(Math.abs(pow)-1 || 0, 0)) + lead + decimal
    : sign + lead + (+pow >= decimal.length ? (decimal + "0".repeat(Math.max(+pow-decimal.length || 0, 0))) : (decimal.slice(0,+pow)+"."+decimal.slice(+pow)))
  }

  console.log(userdata,"==")

  return (
    <div>
      <Navbar_inner />
      <div className="autopool">
       <section className="autopoolban">
       <div className="container">
       <div className="">
        <div className="autopool_box">
        <div>
         <h1>Autopool</h1>
         <p>Enter into Bonjour’s special offerings!</p>
         <p>Encourages wider participation and helps to ensure continued growth and success. Activates high performance and rewards top performers with additional income.</p>
         {userdata && userdata.isExist && userdata.investmentAmount == 0  ? 
           <div class="input-group mb-3">
              <input type="number" min={minval} max={maxval} class="form-control" onChange={investamtchange} value={investamt}/>
             <span class="input-group-text" id="basic-addon2">BUSD</span>
           </div>
           :((localStorage.getItem("account") && localStorage.getItem("account")!="" && localStorage.getItem("account")!=undefined && localStorage.getItem("account")!=null && userdata && !userdata.isExist ) || (userdata.isExist=="" || userdata.isExist==null || userdata.isExist=="empty" || userdata.isExist == undefined)) &&
           <div class="input-group mb-3">
              <input type="number" min={minval} max={maxval} class="form-control" onChange={investamtchange} value={investamt}/>
             <span class="input-group-text" id="basic-addon2">BUSD</span>
           </div>
         }
         {localStorage.getItem("account") && localStorage.getItem("account")!="" && localStorage.getItem("account")!=undefined && localStorage.getItem("account")!=null && userdata && !userdata.isExist &&
            <div class="input-group mb-3">
              <input type="text" class="form-control" onChange={referralchange} value = {referal} placeHolder="Referral Address ( Optional )" />
            </div>
          }
         {userdata && userdata.isExist && userdata.investmentAmount == 0 ?
            <button class="primary_btn joinnow" type="button" onClick={progress && progress=="true" ? ()=>empty() : ()=>joinnow()}>{progress && progress=="true" ? " Processing... Please Wait ":"ReInvest"}</button>
            : localStorage.getItem("account") && localStorage.getItem("account")!="" && localStorage.getItem("account")!=undefined && localStorage.getItem("account")!=null && userdata && !userdata.isExist ? 
            <button class="primary_btn joinnow" type="button" onClick={progress && progress=="true" ? ()=>empty() : ()=>joinnow()}>{progress && progress=="true" ? " Processing... Please Wait ":"Join"}</button>
            : (userdata.isExist=="" || userdata.isExist==null || userdata.isExist=="empty" || userdata.isExist == undefined ) &&
            <button class="primary_btn joinnow" type="button" data-bs-toggle="modal" data-bs-target="#connect_wallet_modal">Connect Wallet</button>
         }
          { userdata && userdata.isExist && userdata.investmentAmount>0 && (parseFloat(userdata.investmentAmount)*2)==parseFloat(userdata.earnedAmount) &&
            <button class="primary_btn withdraw" type="button" onClick={progress && progress=="true" ? ()=>empty() : ()=>withdraw()}>{progress && progress=="true" ? " Processing... Please Wait ":"Withdraw"}</button>
          }

        </div>
        </div>
        </div>
        </div>
       </section>
       {localStorage.getItem("account") && localStorage.getItem("account")!="" && localStorage.getItem("account")!=undefined && localStorage.getItem("account")!=null && 
        <div className="container pb-5">
          <div className="wallet_div autopool_wallet_div addressBox">
            <label>Your Wallet</label>
            <div className="inp_grp">
              <input
                type="text"
                className="form-control primary_inp"
                disabled="disabled"
                value={localStorage.getItem("account")}
              />
              <button className="copy_btn" type="button" onClick={copyToClipboard}>
                <img src={require("../assets/images/copy_icon.png")} className="copy_icon wallet_icon" alt="Copy" /></button>
            </div>
          </div>
          {userdata && userdata.isExist &&
            <div className="wallet_div autopool_wallet_div addressBox">
              <label>Your Referral Link</label>
              <div className="inp_grp">
                <input
                  type="text"
                  className="form-control primary_inp"
                  disabled="disabled"
                  value={config.Front_URL+"bonjourpool/"+ localStorage.getItem("account")}
                />
                <button className="copy_btn" type="button" onClick={copyToClipboardlink}>
                  <img src={require("../assets/images/copy_icon.png")} className="copy_icon wallet_icon" alt="Copy" /></button>
              </div>
            </div>
          }
        </div>
        }

        {localStorage.getItem("account") && localStorage.getItem("account")!="" && localStorage.getItem("account")!=undefined && localStorage.getItem("account")!=null && userdata && userdata.isExist && newgainamt && parseFloat(newgainamt)>0 && 
        <div>
          <div className="container">
            <div className="text-center claimbox">
            <p>Use this amazing opportunity to add more to your income !</p>
            {newprogress=="false" ?
              <button className="btn1" type="button" onClick={()=>newclaimreward()}>
               claim
              </button>
              :
              <button className="btn1" type="button">
               Processing..
              </button>
            }
            </div>
          </div>
        </div>
        }
       {localStorage.getItem("account") && localStorage.getItem("account")!="" && localStorage.getItem("account")!=undefined && localStorage.getItem("account")!=null && userdata && userdata.isExist &&
          <section className="details">
            <div className="container">
              <div className="row">
                <div className="col-lg-4">
                  <div className="box">
                    <div className="round">
                      <img
                        src={require("../assets/images/d1.png")}
                        className="img-fluid"
                        alt="Icon"
                      />
                    </div>
                    <div className="shape">
                      <p>My ID</p>
                      <h4>{userdata && userdata.id ? userdata.id : ""}</h4>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="box">
                    <div className="round">
                      <img
                        src={require("../assets/images/d2.png")}
                        className="img-fluid"
                        alt="Icon"
                      />
                    </div>
                    <div className="shape">
                      <p>My Investment</p>
                      <h4>{userdata && userdata.investmentAmount ? (parseFloat(userdata.investmentAmount)/1e18).toFixed(2):0}</h4>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="box">
                    <div className="round">
                      <img
                        src={require("../assets/images/d3.png")}
                        className="img-fluid"
                        alt="Icon"
                      />
                    </div>
                    <div className="shape">
                      <p>Total Gain Amount</p>
                      <h4>{totalgainamt ? (parseFloat(totalgainamt)/1e18).toFixed(2):0}</h4>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="box">
                    <div className="round">
                      <img
                        src={require("../assets/images/d4.png")}
                        className="img-fluid"
                        alt="Icon"
                      />
                    </div>
                    <div className="shape">
                      <p>Earned Amount</p>
                      <h4>{userdata && userdata.earnedAmount ? (parseFloat(userdata.earnedAmount)/1e18 + parseFloat(roiupto)/1e18).toFixed(2):roiupto?(parseFloat(roiupto)/1e18).toFixed(2):0}</h4>
                    </div>
                  </div>
                </div>
                {/*<div className="col-lg-4">
                  <div className="box">
                    <div className="round">
                      <img
                        src={require("../assets/images/d4.png")}
                        className="img-fluid"
                        alt="Icon"
                      />
                    </div>
                    <div className="shape">
                      <p>ROI Income</p>
                      <h4>{ roiupto ? (parseFloat(roiupto)/1e18).toFixed(2):0}</h4>
                    </div>
                  </div>
                </div> */}
                <div className="col-lg-4">
                  <div className="box">
                    <div className="round">
                      <img
                        src={require("../assets/images/d5.png")}
                        className="img-fluid"
                        alt="Icon"
                      />
                    </div>
                    <div className="shape">
                      <p>Referral Amount</p>
                      <h4>{refgain ? (parseFloat(refgain)/1e18).toFixed(2):0}</h4>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="box">
                    <div className="round">
                      <img
                        src={require("../assets/images/d5.png")}
                        className="img-fluid"
                        alt="Icon"
                      />
                    </div>
                    <div className="shape">
                      <p>Total Participants</p>
                      <h4>{totaluser}</h4>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="box">
                    <div className="round">
                      <img
                        src={require("../assets/images/d6.png")}
                        className="img-fluid"
                        alt="Icon"
                      />
                    </div>
                    <div className="shape">
                      <p>Position</p>
                      <h4>{userdata && userdata.id ? userdata.id : "" }</h4>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4">
                  <div className="box">
                    <div className="round">
                      <img
                        src={require("../assets/images/d7.png")}
                        className="img-fluid"
                        alt="Icon"
                      />
                    </div>
                    <div className="shape">
                      <p>Locked Amount</p>
                      <h4>{userdata && userdata.claimBalance ? (parseFloat(userdata.claimBalance)/1e18).toFixed(2):0}</h4>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="box">
                    <div className="round">
                      <img
                        src={require("../assets/images/d8.png")}
                        className="img-fluid"
                        alt="Icon"
                      />
                    </div>
                    <div className="shape">
                      <p>Reinvest Count</p>
                      <h4>{reinvestcount}</h4>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="box">
                    <div className="round">
                      <img
                        src={require("../assets/images/d9.png")}
                        className="img-fluid"
                        alt="Icon"
                      />
                    </div>
                    <div className="shape">
                      <p>BNJR Balance</p>
                      <h4>{usertokenbalance ? (parseFloat(usertokenbalance)/1e18).toFixed(2):0}</h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        }
       {/*{localStorage.getItem("account") && localStorage.getItem("account")!="" && localStorage.getItem("account")!=undefined && localStorage.getItem("account")!=null && userdata && userdata.isExist &&
        <section className="pooladdress">
          <div className="container">
          <div className="box">
          <h2>Pool Address</h2>
          <div className="table-responsive">
            <ReactDatatable
              config={configdata}
              records={poolarray}
              columns={columns}
            />
          </div>
          </div>
          </div>
        </section>
      }*/}
      </div>
    </div>
  );
}
