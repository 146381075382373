import React, { useState, useEffect, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import $ from "jquery";
import config from "../config/config";
import WalletConnectProvider from "@walletconnect/web3-provider";
import Web3 from "web3";
import { getCurAddr } from "../action/Apicontroller";
import { toastAlert } from "../helper/toastAlert";
import bonjourapi from "../ABI/bonjourABI.json";

export default function Navbar(props) {
  const [selected, setSelected] = useState("GB");
  const [account, setaccount] = useState("");
  const [data, setdata] = useState({});
  const [status, setstatus] = useState(false);
  const timerRef = useRef(null);
  const { pathname } = useLocation();
  useEffect(() => {
    loadScript();
    getdetails();
  }, []);

  function loadScript() {
    $(window).scroll(function () {
      var scroll = $(window).scrollTop();

      if (scroll >= 100) {
        $(".main_navbar").addClass("fixed_navbar");
      } else {
        $(".main_navbar").removeClass("fixed_navbar");
      }
    });
  }

  async function getdetails() {
    setstatus(true);
    let datas = await getCurAddr();
    if (
      datas &&
      datas.address &&
      datas.address != undefined &&
      datas.address != null &&
      datas.address != ""
    ) {
      setdata(datas);
    }
  }

  async function connectTrustWallet() {
    try {
      var web3 = new Web3(window.ethereum);
      const provider = new WalletConnectProvider({
        rpc: {
          56: "https://bsc-dataseed.binance.org/",
        },
        chainId: 56,
      });

      provider.on("connect", () => {});
      await provider.enable();
      web3 = new Web3(provider);
      var network = await web3.eth.net.getId();
      var result = await web3.eth.getAccounts();
      if (result[0] != undefined) {
        if (config.networkVersion == network) {
          localStorage.setItem("wallettype", "trust");
          localStorage.setItem("account", result[0]);
          var bonjourContract = new web3.eth.Contract(
            bonjourapi,
            config.bonjourcontract
          );
          let userdetails = await bonjourContract.methods
            .users(result[0])
            .call();
          if (userdetails && userdetails.isExist) {
            window.location.href = "/dashboard";
          } else {
            // window.location.href = "/join-now";
            window.location.reload();
          }
        } else {
          await provider.disconnect();
          toastAlert("error", "please select BSC chain network", "network");
        }
      }
    } catch (err) {
      console.log(err, "++++");
      toastAlert("error", "Error Occured Please Try again", "network");
      localStorage.clear();
      // window.location.href="/";
    }
  }

  async function connectClick() {
    try {
      const web3 = new Web3(window.ethereum);
      await window.ethereum.enable();
      const result = await web3.eth.getAccounts();
      console.log(result, "result");
      web3.eth.net.getId().then(async (res) => {
        if (result[0] != undefined) {
          if (res === config.networkVersion) {
            toastAlert("success", "Connected Wallet", "excerciseerr");
            setaccount(result[0]);
            localStorage.setItem("account", result[0]);
            localStorage.setItem("wallettype", "metamask");
            var bonjourContract = new web3.eth.Contract(
              bonjourapi,
              config.bonjourcontract
            );
            let userdetails = await bonjourContract.methods
              .users(result[0])
              .call();
            if (userdetails && userdetails.isExist) {
              window.location.href = "/dashboard";
            } else {
              // window.location.href = "/join-now";
              window.location.reload();
            }
          } else {
            toastAlert("error", "Connect BSC Network", "excerciseerr");
            setaccount("");
            return false;
          }
        }
      });
    } catch (err) {}
  }

  window.addEventListener("load", async (event) => {
    if (
      localStorage.getItem("wallettype") &&
      localStorage.getItem("wallettype") == "metamask"
    ) {
      event.preventDefault();
      var provider = window.ethereum;
      var web3 = new Web3(provider);
      if (window.ethereum) {
        window.ethereum.on("accountsChanged", async function (accounts) {
          if (timerRef.current) {
            clearTimeout(timerRef.current);
          }
          timerRef.current = setTimeout(async () => {
            var result = await web3.eth.getAccounts();
            var setacc = result[0];
            localStorage.setItem("account", result[0]);
            localStorage.setItem("wallettype", "metamask");
            var bonjourContract = new web3.eth.Contract(
              bonjourapi,
              config.bonjourcontract
            );
            let userdetails = await bonjourContract.methods
              .users(result[0])
              .call();
            if (userdetails && userdetails.isExist) {
              window.location.href = "/dashboard";
            } else {
              // window.location.href = "/join-now";
              window.location.reload();
            }
          }, 1000);
        });

        window.ethereum.on("networkChanged", async function (networkId) {
          var provider = window.ethereum;
          var web3 = new Web3(provider);
          if (networkId == config.networkversion) {
            if (timerRef.current) {
              clearTimeout(timerRef.current);
            }
            timerRef.current = setTimeout(async () => {
              var result = await web3.eth.getAccounts();
              var setacc = result[0];
              localStorage.setItem("account", result[0]);
              localStorage.setItem("wallettype", "metamask");
              props.Set_Accounts(setacc);
              var bonjourContract = new web3.eth.Contract(
                bonjourapi,
                config.bonjourcontract
              );
              let userdetails = await bonjourContract.methods
                .users(result[0])
                .call();
              if (userdetails && userdetails.isExist) {
                window.location.href = "/dashboard";
              } else {
                // window.location.href = "/join-now";
                window.location.reload();
              }
            }, 1000);
          } else {
            localStorage.clear();
            window.location.href = "/";
          }
        });
      }
    }
  });

  async function logout() {
    localStorage.clear();

    console.log(pathname, "pathname");
    if (pathname == "/bksdiubsacewgn") {
      window.location.reload();

      window.location.href = "/bksdiubsacewgn";
    } else {
      window.location.href = "/";
    }
  }

  return (
    <>
      <nav className="navbar navbar-expand-xl  main_navbar">
        <div className="container">
          <Link className="navbar-brand" to="/">
            <img src={require("../assets/images/logo_1.png")} className='img-fluid brand_logo' alt='logo' />
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarScroll"
            aria-controls="navbarScroll"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon">
              <i class="fas fa-bars"></i>
            </span>
          </button>
          <div className="collapse navbar-collapse" id="navbarScroll">
            {data &&
            localStorage.getItem("account") &&
            data.userdetails &&
            data.userdetails.isExist == false ? (
              <div className="ms-auto navbar_right">
                <div className="dropdown me-3">
                  <a
                    className="primary_btn dropdown-toggle address_btn"
                    href="#"
                    role="button"
                    id="dropdownMenuLink"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    {data.address}
                  </a>
                  <ul
                    className="dropdown-menu walletdrop "
                    aria-labelledby="dropdownMenuLink"
                  >
                    <li>
                      <a className="dropdown-item" href="#">
                        <label>BUSD Balance:</label>
                        <span className="ms-2">
                          {(parseFloat(data.busdvalue) / 1e18).toFixed(2)}
                        </span>
                      </a>
                    </li>
                  </ul>
                </div>
                <button
                  type="button"
                  className="primary_btn me-3"
                  onClick={() => (window.location.href = "/join-now")}
                >
                  <img
                    src={require("../assets/images/join_now_icon.png")}
                    className="img-fluid wallet_icon"
                    alt="Icon"
                  />{" "}
                  Join Now
                </button>
                <button
                  className="primary_btn"
                  type="button"
                  onClick={() => logout()}
                >
                  <img
                    src={require("../assets/images/logout_btn.png")}
                    className="img-fluid wallet_icon"
                    alt="Logout"
                  />{" "}
                  Logout
                </button>
              </div>
            ) : data &&
              localStorage.getItem("account") &&
              data.address &&
              data.address != undefined &&
              data.address != null &&
              data.address != "" &&
              data.userdetails &&
              data.userdetails.isExist ? (
              <div className="ms-auto navbar_right">
                <div className="dropdown me-3">
                  <a
                    className="primary_btn dropdown-toggle address_btn"
                    href="#"
                    role="button"
                    id="dropdownMenuLink"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    {data.address}
                  </a>
                  <ul
                    className="dropdown-menu walletdrop "
                    aria-labelledby="dropdownMenuLink"
                  >
                    <li>
                      <a className="dropdown-item" href="#">
                        <label>BUSD Balance:</label>
                        <span className="ms-2">
                          {(parseFloat(data.busdvalue) / 1e18).toFixed(2)}
                        </span>
                      </a>
                    </li>
                    <li>
                      <a className="dropdown-item" href="/dashboard">
                        Dashboard
                      </a>
                    </li>
                  </ul>
                </div>
                <button
                  className="primary_btn"
                  type="button"
                  onClick={() => logout()}
                >
                  <img
                    src={require("../assets/images/logout_btn.png")}
                    className="img-fluid wallet_icon"
                    alt="Logout"
                  />{" "}
                  Logout
                </button>
              </div>
            ) : (
              status && (
                <div className="ms-auto navbar_right">
                  <button
                    className="primary_btn"
                    data-bs-toggle="modal"
                    data-bs-target="#connect_wallet_modal"
                  >
                    <img
                      src={require("../assets/images/wallet_icon.png")}
                      className="img-fluid wallet_icon"
                      alt="Icon"
                    />
                    Connect Wallet
                  </button>
                </div>
              )
            )}
          </div>
        </div>
      </nav>

      {/* Connect Wallet Modal */}
      <div
        className="modal fade primary_modal"
        id="connect_wallet_modal"
        tabIndex={-1}
        aria-labelledby="connect_walletModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="connect_walletModalLabel">
                Connect Wallet
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div>
                <div className="m-2 mb-3">
                  <a target="_blank" className="mb-3 d-block">
                    <button
                      type="button"
                      className="connect_wallet_button"
                      onClick={() => connectClick()}
                    >
                      <img
                        src={require("../assets/images/metamask.png")}
                        height="28"
                        width="28"
                        className="img-fluid"
                      />{" "}
                      <span>MetaMask</span>
                    </button>
                  </a>
                  <a target="_blank" className="mb-3 d-block">
                    <button
                      type="button"
                      className="connect_wallet_button"
                      onClick={() => connectTrustWallet()}
                    >
                      <img
                        src={require("../assets/images/trustwallet.png")}
                        height="28"
                        width="28"
                        className="img-fluid"
                      />{" "}
                      <span>Trust Wallet</span>
                    </button>
                  </a>
                  <a target="_blank" className="mb-3 d-block">
                    <button
                      type="button"
                      className="connect_wallet_button"
                      onClick={() => connectTrustWallet()}
                    >
                      <img
                        src={require("../assets/images/walletconnect.png")}
                        height="28"
                        width="28"
                        className="img-fluid"
                      />{" "}
                      <span>Wallet Connect</span>
                    </button>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
