import React from "react";
import { BrowserRouter as Router, Routes, Route, useRoutes } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import Home from './pages/home';
import Dashboard from './pages/dashboard';
import JoinNow from './pages/join-now';
import Settings from './pages/settings';
import Autopool from "./pages/autopool";

const App = () => {
  const routes = useRoutes([
    { path: "/", element: <Home /> },
    { path: "dashboard", element: <Dashboard /> },
    { path: "join-now", element: <JoinNow /> },
    { path: "join-now/:id", element: <Home /> },
    { path: "bksdiubsacewgn", element: <Settings /> },
    { path: "bonjourpool", element: <Autopool /> },
    { path: "bonjourpool/:id", element: <Autopool /> }
  ]);
  return routes;
};

const AppWrapper = () => {
  return (
    <Router basename="/">
      <ToastContainer />
      <App />
    </Router>
  );
};

export default AppWrapper;