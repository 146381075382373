import React from 'react';
import config from "../config/config";

export default function Footer(props) {
  return (
    <footer className="footer">
      <div className="container">
        <div className='footer_panel'>
          <div className='footer_panel_left'>
            <p>Smart-contract: </p>
            <a href={config.bscurl+"address/"+config.bonjourcontract} target="_blank">{config.bonjourcontract}</a>
            {/* <p>Copy right © {new Date().getFullYear()} Bonjour, All rights Reserved</p> */}
            <p>Support : support@webonjour.com</p>       
            <ul className='footer_social_links'>
             {/* <li><a href="/" target="_blank"><i className="fab fa-facebook-f"></i></a></li>
              <li><a href="/" target="_blank"><i className="fab fa-twitter"></i></a></li>
              <li><a href="/" target="_blank"><i className="fab fa-linkedin-in"></i></a></li>*/}
              <li><a href="https://t.me/bonjourofficialcommunity" target="_blank"><i className="fab fa-telegram-plane"></i></a></li>
              {/*<li><a href="/" target="_blank"><i class="fab fa-youtube"></i></a></li>*/}
            </ul>
          </div>  
        </div>
      </div>
    </footer>
  );
}